@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300&display=swap');

/* COLORS */

:root {
  --mainbg:rgb(248, 248, 248);
  /* --borders:#16425B; */
  --borders:#16425B;
  --areabg: #81C3D7;
  --boxbg:rgb(248, 248, 248);
  --boxheader: #16425B;
  --boxheaderHoover: #256b94;
  --boxLight: #2F6690;
  --shadow:#115277 ;
  --fontClear: white;
  --fontDark: #0E2430;
}
.testcolors{
color: #2F6690;
color: #3A7CA5;
color: #D9DCD6;
color: #16425B;
color: #81C3D7;
}

/* Structure */

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	margin: 0;
	padding: 0;
}
  
html, body {
  font-family: 'IBM Plex Sans','Montserrat', sans-serif;
  font-weight: 400;
  height: 100%;
  margin:0;
  font-size: 62.5%;
  line-height: 1;
  color: var(--fontDark);
  background-color: var(--mainbg);;
}

.row {      
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 5rem;
}
/* STD HTML TAGS */
b {
  font-weight: bold;
}

a {
  text-decoration: none;
	color: black;
  color: var(--fontDark);
  font-weight: 700;
}

p {
  font-weight: 400;
  font-family: 'IBM Plex Sans', 'Montserrat', sans-serif;
}

img {
  display: block;
	
}

.collapsible {
  cursor: pointer;
  padding: 0px;
  width: 100%;
  border: 1px;
  text-align: left;
  outline: 1px;
  font-size: 1.2rem;
  border-radius: 3px;
  height:min-content;
  align-content: start; 
  align-self: flex-start;
  vertical-align: top;
  background-color: var(--boxheader);
  border-color: var(--borders);
  outline-color: var(--borders);
  color: var(--fontDark);
}

.active, .collapsible:hover {
  background-color: var(--boxheaderHoover);
}

.headertitle{
  padding-left: 5px;
  font-weight: 400;
  color:var(--fontDark);
}

.contentblock {
  border: 1px;
  border-radius: 4px;
  height:max-content;
  width: 100%;
  text-align: left;
  padding: 0px;
  background-color: var(--boxLight);;
  border-color: var(--borders);
  box-shadow: 3px 5px var(--shadow);
}

.App-header{
  border-radius: 5px;
  color:var(--fontDark);
  } 

.pageheader {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 10px;
  vertical-align: center;  
  gap: 2rem;
  justify-content: space-evenly;
  padding: 2rem 1rem 0 1rem;

}

.headerpicture {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}
.headername {
  font-size:1.6rem;
  font-weight: bold;
  vertical-align: middle;
  font-family: 'IBM Plex Sans', 'Montserrat', sans-serif;
}

.header--socials {
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display,inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto;
  display: flex;
    gap: 0.5rem;
    font-size: 2.4rem;
}

.header--social-icon {
  padding:0%
} 

.header--social-icon:hover {
  transform: scale(1.05);
} 

.mainpage {
  display: flex;
  flex-direction:row;
  flex-flow: row;
  max-width: 100%;
}

.sectionTitle{
  font-family: 'IBM Plex Sans', 'Montserrat', sans-serif;
  font-size: 3rem;
  
}


.aboutme {
  height:max-content;
  width: 100%;
  text-align: justify;
  padding: 5px;
  font-family: 'IBM Plex Sans', 'Montserrat', sans-serif;
}

.aboutmeText{
  padding: 5px;
  font-size: 0.8rem;
  color: var(--fontDark);
}

.skillsList {
  list-style-type: none;
  height: 4rem;
  display: block;
  width: 100%;
  display: list-item;
  text-align: -webkit-match-parent;
  list-style-type: none;
  line-height: 0.5rem;
}

.skillsList ul {
	display: flex;
	gap: 0.7rem;
	justify-content: center;
	flex-wrap: wrap;
  list-style-type: none;
}

.skillsList li {
	padding: 0.8rem;
	border-radius: 15px;
	font-weight: 300;
	font-size: 0.7rem;
  color: white;
}

.skillsList i {
  margin-right: 10px;
}

.arduino {background: #ff8e5d;}
.react {background: #5b89f6;}
.php {background: #e4ce3d;}
.pihole {background: rgb(214, 123, 241);}
.pinhole {background: #e18399;}
.solidity {background: #02d083;}
.nodejs {background: rgb(106, 106, 221);}
.javascript {background: rgb(67, 67, 136);}
.mysql {background: rgb(214, 123, 241);}
.mqtt {background: rgb(67, 67, 136);}
.html {background: #ff8e5d;}
.css {background: #02d083;}
.raspberrypi {background: #5b89f6;}

.Card {
width: 20rem;
height: 30rem;
border-radius: 20px;
border: 1px solid;
padding: 1rem;
margin:0px;
margin-bottom: 5px;
font-size: 0.7rem;
transition: all 200ms ease-in-out;
display: flex;
flex-direction: column;
align-items: center;
border-color:var(--borders);
background-color: var(--boxbg);
box-shadow: 2px 3px var(--shadow);
}

.Card:hover {
	transform: scale(1.05);
	box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 1),
		-1px -1px 1px 1px rgba(0, 0, 0, 1);
}

.card--contents{
  height:100%;
  width:100%;
  position:relative;
  /* background-color: rgb(67, 67, 136); */
}

.project--title {
  font-size: 1.2rem;
  position: relative;
  top: 1.5rem;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

 .projectLink {
  font-size: 2rem;
  font-weight: light;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  color: var(--fontDark);
}

.projectCat {
  font-size: 0.8rem;
  text-align: left;
  position: relative;
  top: 1.8rem;
}

.projectDescr {
  font-size: 0.8rem;
  text-align: left;
  line-height: 1.4;
  position: relative;
  top: 2rem;
  height: 8rem;
  overflow: hidden;

}
.projectFotos {
  display:flex;
  flex-direction: row;
  margin: 2rem;
  gap: 1rem;
  position:absolute ;
  bottom:1rem;
}

.foto1 {
  width: 6rem;
  height: 4rem;
  display: block;
  border: none;
  background: #121212;
  color: white;
  border-radius: 16px;
  border-color:var(--borders);
}

.projectStatus {
  font-size: 0.8rem;
  text-align: right;
  position:absolute;
  bottom: -2rem;
  
 
}

.linkstext {
  text-align: left;
}

.footer { 
  /* do nothing */
 background-color: rgba(0, 0, 0, 0.5);
}
.footertext {
  font-size: small;
  text-align: right;
  font-weight: 100;
  font-family: 'IBM Plex Sans',  'Montserrat', sans-serif;
}

.pagefooter {
  background-color:var(--areabg);;
}

.active, .pagefooter:hover {
  background-color: var(--boxheader);
}

@media only screen and (max-width: 1000px) {
	.row {
		grid-template-columns: repeat(2, auto);
	}
}

@media only screen and (max-width: 650px) {
	.row {
		display: grid;
		grid-template-columns: repeat(2, auto);
	}

	.row {
		grid-template-columns: auto;
	}
}

@media only screen and (max-width: 450px) {
	.header-bar li:nth-of-type(2) {
		font-size: 1.4rem;
		text-align: center;
		line-height: 1.4;
	}

	.divider {
		display: none;
	}
	.header-bar br {
		display: block;
	}
}